var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.selected,
              expression: "form.selected",
            },
          ],
          staticStyle: { padding: "5px 0", height: "47px" },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.form,
                  "selected",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              function ($event) {
                return _vm.optionSelected()
              },
            ],
          },
        },
        [
          _c("option", { attrs: { value: "" } }, [
            _vm._v("Sélectionner une nouvelle livraison"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.offers, function (offer, index) {
            return _c(
              "option",
              { key: index, domProps: { value: offer } },
              [_vm._t("offer", null, { offer: offer })],
              2
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }