var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", [_vm._v(_vm._s(_vm.address.first_name))]),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.address.last_name))]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm.address.company_name
      ? _c("span", [
          _c("span", [_vm._v(_vm._s(_vm.address.company_name))]),
          _vm._v(" "),
          _c("br"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("span", [
      _c("a", { attrs: { href: _vm.link, target: "_blank" } }, [
        _vm._v(_vm._s(_vm.address.street_name)),
      ]),
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm.address.address_line_2
      ? _c("span", [
          _c("span", [_vm._v(_vm._s(_vm.address.address_line_2))]),
          _vm._v(" "),
          _c("br"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.address.postcode))]),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.address.city))]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("span", [
      _vm._v(
        _vm._s(_vm.flag(_vm.address.country)) +
          " " +
          _vm._s(_vm.address.country)
      ),
    ]),
    _vm._v(" "),
    _c("br"),
    _c("br"),
    _vm._v(" "),
    _vm.address.phone_number
      ? _c("span", [
          _c(
            "a",
            {
              attrs: { href: `tel:${_vm.address.phone_number.international}` },
            },
            [_vm._v(_vm._s(_vm.address.phone_number.international))]
          ),
          _vm._v(" "),
          _c("br"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }