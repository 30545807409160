var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isOrderEntryDeliveryLoading
        ? _c("IcLoader", { staticClass: "animate-spin" })
        : _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "property-container" }, [
              _c("span", { staticClass: "property" }, [_vm._v("Type :")]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderEntryDelivery.label)),
              ]),
              _vm._v(" "),
              _vm.orderEntryDelivery.shipper
                ? _c("span", { staticClass: "shipper-label" }, [
                    _vm._v(
                      "\n       - " +
                        _vm._s(_vm.orderEntryDelivery.shipper.displayName) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "property-container" }, [
              _c("span", { staticClass: "property" }, [_vm._v("Statut :")]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderEntryDelivery.status)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "property-container" }, [
              _c("span", { staticClass: "property" }, [
                _vm._v("Id de livraison :"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderEntryDelivery.id)),
              ]),
            ]),
            _vm._v(" "),
            _vm.shouldDisplayTaskBlock
              ? _c("div", [
                  _vm.orderEntryDelivery.isTaskSent
                    ? _c(
                        "span",
                        { staticClass: "property" },
                        [
                          _vm._v("\n        ✅ Tâche créée\n        "),
                          _vm.hasShippingServiceLink
                            ? [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.orderEntryDelivery
                                        .pickupShippingServiceLink,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v("Lien vers la tâche "),
                                    _vm.hasTwoShippingServiceLinks
                                      ? _c("span", [_vm._v(" de pickup")])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.hasTwoShippingServiceLinks
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.orderEntryDelivery
                                            .deliveryShippingServiceLink,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Lien vers la tâche de livraison"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _c(
                        "span",
                        { staticClass: "property" },
                        [
                          _vm._v("\n        ❌ Tâche non créée "),
                          _vm.orderEntryDelivery.status === "pending_validation"
                            ? [
                                _vm._v(
                                  "(en attente d'une validation de la disponibilité produit)"
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                  _vm._v(" "),
                  _vm.shouldDisplayDownloadDeliveryNoteLink
                    ? _c(
                        "button",
                        {
                          staticClass: "button-secondary cta-delivery-note",
                          on: { click: _vm.downloadOrderEntryDeliveryNote },
                        },
                        [_vm._v("\n        Télécharger l'étiquette\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shouldDisplayTaskCreationButton
                    ? _c(
                        "button",
                        {
                          staticClass: "button-primary cta-task-creation",
                          on: {
                            click: function ($event) {
                              return _vm.createTask()
                            },
                          },
                        },
                        [
                          _vm.isTaskCreationLoading
                            ? _c("IcLoader", { staticClass: "animate-spin" })
                            : _c("span", [
                                _vm._v(
                                  "\n          Créer la tâche " +
                                    _vm._s(
                                      _vm.orderEntryDelivery.shippingService
                                    ) +
                                    "\n        "
                                ),
                              ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "error" },
              [
                _vm.taskCreationError
                  ? [
                      _vm._v(
                        "\n        Erreur de création de tâche " +
                          _vm._s(_vm.orderEntryDelivery.shippingService) +
                          " : " +
                          _vm._s(_vm.taskCreationError) +
                          "\n      "
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }