var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("h2", [_vm._v("Ajout")]),
      _vm._v(" "),
      _c("div", { staticClass: "add-admin" }, [
        _c("form", { on: { submit: _vm.search } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.search,
                expression: "form.search",
              },
            ],
            attrs: {
              type: "email",
              autocomplete: "false",
              placeholder: "Chercher par email...",
            },
            domProps: { value: _vm.form.search },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "search", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _vm.user
          ? _c("div", { staticClass: "admin" }, [
              _c("b", [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.user.firstname) + " " + _vm._s(_vm.user.lastname)
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "user-id" }, [
                _vm._v(_vm._s(_vm.user.user_id)),
              ]),
              _vm._v(" "),
              _vm.user.isAdmin
                ? _c(
                    "button",
                    {
                      staticClass: "button-primary button-small make-admin",
                      attrs: { disabled: "" },
                    },
                    [_vm._v("Déjà admin 🤴")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.user.isAdmin
                ? _c(
                    "button",
                    {
                      staticClass: "button-primary button-small make-admin",
                      on: {
                        click: function ($event) {
                          return _vm.makeAdmin(_vm.user)
                        },
                      },
                    },
                    [_vm._v("Passer admin 👑")]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Liste")]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", [
            _c("i", { staticClass: "loading-dots" }, [
              _vm._v("Chargement des admins"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.admins, function (admin) {
        return _c("div", { key: `admin-${admin.id}`, staticClass: "admin" }, [
          _c("b", [
            _c("span", [
              _vm._v(_vm._s(admin.firstname) + " " + _vm._s(admin.lastname)),
            ]),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "user-id" }, [_vm._v(_vm._s(admin.id))]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(admin.email))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "roles" },
            [
              _vm._l(admin.roles, function (role) {
                return _c(
                  "span",
                  {
                    key: `admin-${admin.id}-${role}`,
                    staticClass: "role",
                    class: _vm.isRemoving(admin.id, role) ? "removing" : "",
                    on: {
                      click: function ($event) {
                        return _vm.remove(admin, role)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(role) + " ")]),
                    _vm._v(" "),
                    _vm.isRemoving(admin.id, role)
                      ? _c("span", [_vm._v("| enlever?")])
                      : _vm._e(),
                  ]
                )
              }),
              _vm._v(" "),
              !(_vm.form.editing && _vm.form.id === admin.id)
                ? _c(
                    "span",
                    {
                      staticClass: "edit-admin",
                      attrs: { value: admin.id },
                      on: { click: _vm.edit },
                    },
                    [_vm._v("+ Ajouter")]
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.form.editing && _vm.form.id === admin.id
            ? _c("div", { staticClass: "edit-admin-form" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: (e) => {
                        e.preventDefault()
                        _vm.save(admin)
                      },
                    },
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.roleSelected,
                            expression: "form.roleSelected",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "roleSelected",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.filterRoles(admin.roles), function (role) {
                        return _c(
                          "option",
                          { key: role, domProps: { value: role } },
                          [_vm._v(_vm._s(role))]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button-primary button-small",
                        attrs: { type: "submit" },
                      },
                      [_vm._v("Valider")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      { staticClass: "cancel-edit", on: { click: _vm.cancel } },
                      [_vm._v("Annuler")]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("h1", [_vm._v("Gestion des admins")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }