var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", [_vm._v(_vm._s(_vm.product.sku))]),
    _vm._v(" "),
    _c("span", [_vm._v(" - ")]),
    _vm._v(" "),
    _c("span", [_c("Country", { attrs: { iso2: _vm.product.country } })], 1),
    _vm._v(" "),
    _c("span", [
      _c(
        "a",
        { attrs: { href: `/#!/moderation/products/edit/${_vm.product.id}` } },
        [_vm._v(_vm._s(_vm.product.title))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }