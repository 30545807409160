var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Modal", {
    scopedSlots: _vm._u([
      {
        key: "panel-content",
        fn: function () {
          return [
            !_vm.getAddDelivery()
              ? _c(
                  "div",
                  [
                    _c("h2", { staticClass: "text-center" }, [
                      _vm._v("Modifier la livraison"),
                    ]),
                    _vm._v(" "),
                    "COCOLIS" === _vm.getProviderType()
                      ? _c("div", [
                          _c("p", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "\n          Vous êtes sur le point de modifier une livraison COCOLIS. "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n\n          N'oubliez pas de retirer l'annonce sur le site "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.cocolis.fr/",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("cocolis.fr")]
                            ),
                            _vm._v(
                              "  si vous validez la modification de livraison !\n        "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row margin-top-update-delivery" },
                      [
                        _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                          _c("h4", [
                            _vm._v(
                              "\n              Livraison actuelle\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                              _c("h4", [
                                _vm._v(
                                  "\n                  Sélectionner\n                "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "add-delivery",
                                  on: {
                                    click: function ($event) {
                                      return _vm.displayAddDelivery()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  Ajouter une option de livraison\n                "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row margin-top-update-delivery" },
                      [
                        _vm.isTurboActivated()
                          ? _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                              _c("div", { staticClass: "bg-color-grey" }, [
                                _c(
                                  "p",
                                  { staticClass: "label inline-block" },
                                  [
                                    _c("DeliveryTitle", {
                                      attrs: { title: _vm.getProviderType() },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "label inline-block price-right",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.getPriceDelivery()) +
                                        " €\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "label inline-block price-right line-through",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.getPricewithTurboActivated()
                                        ) +
                                        " €\n              "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                              _c("div", { staticClass: "bg-color-grey" }, [
                                _c(
                                  "p",
                                  { staticClass: "label inline-block" },
                                  [
                                    _c("DeliveryTitle", {
                                      attrs: { title: _vm.getProviderType() },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "label inline-block price-right",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.getPriceDelivery()) +
                                        " €\n              "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                          _c(
                            "div",
                            [
                              _c("OrderDeliveryOffers", {
                                key: `test-${_vm.deliveryOfferId}`,
                                attrs: {
                                  product: _vm.product,
                                  deliveryOfferId: _vm.deliveryOfferId,
                                  quantity: _vm.quantity,
                                },
                                on: {
                                  optionSelected: _vm.optionSelectedReceived,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "offer",
                                      fn: function (data) {
                                        return [
                                          _c("DeliveryOffer", {
                                            attrs: { offer: data.offer },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  836347482
                                ),
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row margin-top-update-delivery" },
                      [
                        _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                          _c("div", { staticClass: "bg-color-grey" }, [
                            _c("p", { staticClass: "label inline-block" }, [
                              _vm._v("\n                Turbo\n              "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "label inline-block price-right" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.getDeliveryPriceTurbo()) +
                                    " €\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.getOptionDeliverySelected()
                          ? _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                              _c("div", { staticClass: "bg-color-grey" }, [
                                _c("p", { staticClass: "label inline-block" }, [
                                  _vm._v(
                                    "\n                Turbo (après changement de livraison)\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "label inline-block price-right",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.getBoosterFromNewDelivery()
                                        ) +
                                        " €\n              "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.orderProduct.order_services.length > 0
                      ? _c(
                          "div",
                          { staticClass: "row margin-top-update-delivery" },
                          [
                            _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                              _c("div", { staticClass: "bg-color-grey" }, [
                                _c(
                                  "p",
                                  { staticClass: "label inline-block" },
                                  _vm._l(
                                    _vm.orderProduct.order_services,
                                    function (service) {
                                      return _c("OrderService", {
                                        key: service.id,
                                        attrs: { service: service },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "row margin-top-update-delivery" },
                          [
                            _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                              _c("div", { staticClass: "bg-color-grey" }, [
                                _c("p", { staticClass: "label inline-block" }, [
                                  _vm._v(
                                    "\n                Livraison à l'étage\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "label inline-block price-right",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    0 €\n                "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                    _vm._v(" "),
                    !_vm.getOptionDeliverySelected()
                      ? _c("SplitOrderTable", {
                          attrs: {
                            entity: _vm.orderProduct,
                            deliveryFrom: _vm.isDeliveryMarketPlace(),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getOptionDeliverySelected()
                      ? _c("OrderRefund", {
                          attrs: {
                            entity: _vm.orderProduct,
                            newDeliveryEntity: _vm.getOptionDeliverySelected(),
                            deliveryFrom: _vm.isDeliveryMarketPlace(),
                            newDeliveryFrom: _vm.isNewDeliveryByMarketPlace(),
                          },
                          model: {
                            value: _vm.refunds,
                            callback: function ($$v) {
                              _vm.refunds = $$v
                            },
                            expression: "refunds",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getOptionDeliverySelected()
                      ? _c("SplitCompareOrderTable", {
                          attrs: {
                            entity: _vm.orderProduct,
                            newDeliveryEntity: _vm.getOptionDeliverySelected(),
                            deliveryFrom: _vm.isDeliveryMarketPlace(),
                            newDeliveryFrom: _vm.isNewDeliveryByMarketPlace(),
                          },
                          model: {
                            value: _vm.splits,
                            callback: function ($$v) {
                              _vm.splits = $$v
                            },
                            expression: "splits",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.getAddDelivery()
              ? _c(
                  "div",
                  [
                    _c("AddDelivery", {
                      attrs: {
                        product: _vm.product,
                        orderProduct: _vm.orderProduct,
                      },
                      on: { "delivery-offer-id": _vm.deliveryOfferIdAdded },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.getOptionDeliverySelected() && !_vm.getAddDelivery()
              ? _c("div", { staticClass: "line-border-bottom" })
              : _vm._e(),
            _vm._v(" "),
            _vm.getOptionDeliverySelected() && !_vm.getAddDelivery()
              ? _c("form", { on: { submit: (e) => e.preventDefault() } }, [
                  _c("h4", [_vm._v("\n          Notifications\n        ")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.notifications,
                              expression: "notifications",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "notification_email_buyer_refund",
                            value: "notification_email_buyer_refund",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.notifications)
                              ? _vm._i(
                                  _vm.notifications,
                                  "notification_email_buyer_refund"
                                ) > -1
                              : _vm.notifications,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.notifications,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "notification_email_buyer_refund",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.notifications = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.notifications = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.notifications = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "notification_email_buyer_refund" } },
                          [
                            _vm._v(
                              "Notifier l'acheteur du remboursement par email"
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.notification_email_seller,
                              expression: "notification_email_seller",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "notification_email_seller",
                            value: "notification_email_seller",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.notification_email_seller
                            )
                              ? _vm._i(
                                  _vm.notification_email_seller,
                                  "notification_email_seller"
                                ) > -1
                              : _vm.notification_email_seller,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.notification_email_seller,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "notification_email_seller",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.notification_email_seller = $$a.concat(
                                      [$$v]
                                    ))
                                } else {
                                  $$i > -1 &&
                                    (_vm.notification_email_seller = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.notification_email_seller = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "notification_email_seller" } },
                          [_vm._v("Notifier le vendeur par email")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.notification_email_buyer,
                              expression: "notification_email_buyer",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "notification_email_buyer",
                            value: "notification_email_buyer",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.notification_email_buyer)
                              ? _vm._i(
                                  _vm.notification_email_buyer,
                                  "notification_email_buyer"
                                ) > -1
                              : _vm.notification_email_buyer,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.notification_email_buyer,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "notification_email_buyer",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.notification_email_buyer = $$a.concat([
                                      $$v,
                                    ]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.notification_email_buyer = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.notification_email_buyer = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "notification_email_buyer" } },
                          [_vm._v("Notifier l'acheteur par email")]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.proceed_refund,
                              expression: "proceed_refund",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.proceed_refund)
                              ? _vm._i(_vm.proceed_refund, null) > -1
                              : _vm.proceed_refund,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.proceed_refund,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.proceed_refund = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.proceed_refund = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.proceed_refund = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v("Procéder au remboursement de l'acheteur"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.confirm_update_delivery,
                              expression: "confirm_update_delivery",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.confirm_update_delivery)
                              ? _vm._i(_vm.confirm_update_delivery, null) > -1
                              : _vm.confirm_update_delivery,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.confirm_update_delivery,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.confirm_update_delivery = $$a.concat([
                                      $$v,
                                    ]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.confirm_update_delivery = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.confirm_update_delivery = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { staticStyle: { color: "#4B6965" } }, [
                          _vm._v(
                            "J'ai bien lu et relu, je suis prêt.e, à confirmer definitivement le changement de livraison"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-xs-12 col-md-6" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button-primary",
                                on: { click: _vm.close },
                              },
                              [_c("span", [_vm._v("Annuler")])]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-6" },
                            [
                              _c(
                                "DangerousButton",
                                {
                                  staticClass: "button-primary-green",
                                  attrs: {
                                    disabled: !_vm.confirm_update_delivery,
                                  },
                                  on: { click: _vm.confirm },
                                },
                                [_c("span", [_vm._v("Confirmer")])]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }