var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", [
      _vm._v("📦 Livraison Colissimo "),
      _vm.isColissimoPSA() ? _c("span", [_vm._v("PSA")]) : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.delivery
      ? _c("div", [
          _c(
            "div",
            { staticClass: "row", staticStyle: { "padding-bottom": "20px" } },
            [
              _c("div", { staticClass: "col-xs-5 col-xs-offset-1" }, [
                _c("p", [
                  _c("b", [_vm._v("Assurance : ")]),
                  _vm._v(" "),
                  _vm.delivery.delivery_offer.insurance
                    ? _c("span", [_vm._v("✔️")])
                    : _c("span", [_vm._v("❌")]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("b", [_vm._v("Emballage : ")]),
                  _vm._v(" "),
                  _vm.delivery.delivery_offer.packing_cost &&
                  _vm.delivery.delivery_offer.packing_cost.value
                    ? _c("span", [
                        _vm._v(
                          "\n                          " +
                            _vm._s(
                              _vm.delivery.delivery_offer.packing_cost.value
                            ) +
                            " " +
                            _vm._s(
                              _vm.delivery.delivery_offer.packing_cost.currency
                            ) +
                            "\n                      "
                        ),
                      ])
                    : _c("span", [_vm._v("❌")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("b", [_vm._v("Packages : ")]),
                    _vm._v(" "),
                    _vm.delivery.delivery_offer.packages.length === 0
                      ? _c("span", [_vm._v("⚠️ 0 found ⚠️")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(
                      _vm.delivery.delivery_offer.packages,
                      function (pckg) {
                        return _c("div", { key: pckg }, [
                          _vm._v(
                            "\n                          -\n                          "
                          ),
                          _c("b", [_vm._v("Depth :")]),
                          _vm._v(
                            "  " +
                              _vm._s(pckg.depth) +
                              " cm\n                          "
                          ),
                          _c("b", [_vm._v("Height :")]),
                          _vm._v(
                            " " +
                              _vm._s(pckg.height) +
                              " cm\n                          "
                          ),
                          _c("b", [_vm._v("Width :")]),
                          _vm._v(
                            "  " +
                              _vm._s(pckg.width) +
                              " cm\n                          "
                          ),
                          _c("b", [_vm._v("Weight :")]),
                          _vm._v(
                            " " +
                              _vm._s(pckg.weight) +
                              " kg\n                      "
                          ),
                        ])
                      }
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("p", [
                  _c("b", [_vm._v("Destination : ")]),
                  _vm._v(" "),
                  _vm.delivery.delivery_offer.destination_country
                    ? _c("span", [
                        _vm._v(
                          "\n                          Pays -> " +
                            _vm._s(
                              _vm.delivery.delivery_offer.destination_country
                            ) +
                            "\n                      "
                        ),
                      ])
                    : _vm.delivery.delivery_offer.destination_continent
                    ? _c("span", [
                        _vm._v(
                          "\n                          Continent -> " +
                            _vm._s(
                              _vm.delivery.delivery_offer.destination_continent
                            ) +
                            "\n                      "
                        ),
                      ])
                    : _c("span", [_vm._v("Monde")]),
                ]),
                _vm._v(" "),
                Array.isArray(_vm.delivery.tracking_links) &&
                _vm.delivery.tracking_links.length > 0
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.delivery.tracking_links[0],
                          target: "_blank",
                        },
                      },
                      [
                        _c("button", { staticClass: "button-secondary" }, [
                          _vm._v(
                            "\n                          🔎 Suivi sur le site de Laposte\n                      "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.isColissimoPSA()
                ? _c("div", { staticClass: "col-xs-5" }, [
                    _c("div", { staticClass: "simple-wrapper" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", { staticClass: "simple-content" }, [
                        _vm.delivery.delivery_note_blocked
                          ? _c("p", [
                              _vm._v(
                                "\n                              ⚠️ Un problème a été détecté sur cette étiquette."
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _vm.isShippingCountryNotValid()
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                  --> Le pays de livraison n'est pas géré par notre prestataire d'étiquette\n                              "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                                  Il est en cours de résolution.\n                              "
                                    ),
                                  ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.delivery.delivery_note_blocked
                          ? _c("div", [
                              _c("p", [
                                _vm._v(
                                  "\n                                  ✔️ Aucun problème détecté sur cette étiquette\n                              "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "button-secondary",
                            attrs: { disabled: "" },
                            on: {
                              click: function ($event) {
                                return _vm.toogleIssueForm()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                              📢 Remonter un problème "
                            ),
                            _c("i", [_vm._v("[feature à venir]")]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.displayIssueForm
                          ? _c("div", [
                              _c("hr"),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticStyle: { "font-size": "0.85em" } },
                                [
                                  _vm._v(
                                    "\n                                  ℹ️ Ce formulaire permet de remonter un message pour informer l'équipe technique d'un problème spécifique sur l'étiquette en question\n                              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.reason,
                                        expression: "form.reason",
                                      },
                                    ],
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.form,
                                          "reason",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._m(1),
                                    _vm._v(" "),
                                    _c("option", [
                                      _vm._v("L'étiquette a expirée"),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", [
                                      _vm._v(
                                        "L'adresse de livraison doit être changée"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", [
                                      _vm._v(
                                        "Un problème detecté sur l'étiquette n'est toujours pas résolu"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", [_vm._v("Autre")]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.message,
                                    expression: "form.message",
                                  },
                                ],
                                attrs: {
                                  placeholder: "Détailler ici si nécessaire",
                                },
                                domProps: { value: _vm.form.message },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      "message",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "button-secondary",
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendIssue()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                  Envoyer\n                              "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.showAdminInfo
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xs-10 col-xs-offset-1" }, [
                  _c("div", { staticClass: "simple-wrapper" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "simple-content" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-xs-5 col-xs-offset-1" }, [
                          _c("p", [
                            _c("b", [_vm._v("Delivery Id :")]),
                            _vm._v(" " + _vm._s(_vm.delivery.id)),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _c("b", [_vm._v("Status :")]),
                            _vm._v(" " + _vm._s(_vm.delivery.status)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-5 col-xs-offset-1" }, [
                          _c("p", [
                            _c("b", [_vm._v("Delivery Offer Id :")]),
                            _vm._v(
                              " " + _vm._s(_vm.delivery.delivery_offer.id)
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.shippingDate
                        ? _c("div", { staticClass: "row" }, [
                            _c("p", { staticClass: "col-xs-offset-1" }, [
                              _c("b", [_vm._v("Date d'envoi :")]),
                              _vm._v(" " + _vm._s(_vm.shippingDate)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deliveryDate
                        ? _c("div", { staticClass: "row" }, [
                            _c("p", { staticClass: "col-xs-offset-1" }, [
                              _c("b", [_vm._v("Date de livraison :")]),
                              _vm._v(" " + _vm._s(_vm.deliveryDate)),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "simple-label" }, [
      _c("span", [_vm._v("PSA")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("option", { attrs: { selected: "", hidden: "", value: "" } }, [
      _c("i", [_vm._v("Selectionner un motif")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "simple-label" }, [
      _c("span", [_vm._v("Data helper")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }