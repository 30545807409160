var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "frame" }, [
    _c(
      "div",
      { staticClass: "all_dashboard" },
      [
        _vm._l(_vm.groups, function (group) {
          return _c("div", { key: group, staticClass: "group" }, [
            _c("div", { staticClass: "groupTitle font" }, [
              _vm._v(_vm._s(group.name)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grid_container" },
              _vm._l(_vm.getGroup(group.code_name), function (dashboard) {
                return _c("div", { key: dashboard }, [
                  _c(
                    "a",
                    {
                      staticClass: "card_link",
                      attrs: { href: dashboard.link, target: "_blank" },
                    },
                    [
                      _c("div", { staticClass: "card" }, [
                        _c("div", { staticClass: "top_info_card" }, [
                          _c("div", { staticClass: "title_card font" }, [
                            _vm._v(_vm._s(dashboard.name)),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "logo_card",
                            attrs: {
                              src: "/assets/img/" + dashboard.image + ".svg",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "bottom_info_card" }, [
                          _c("div", { staticClass: "team_text font" }, [
                            _vm._v("Team: " + _vm._s(dashboard.team)),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ])
              }),
              0
            ),
          ])
        }),
        _vm._v(" "),
        _vm._m(0),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bottom_bar" }, [
      _c("span", { staticClass: "bottom_bar_font font" }, [
        _vm._v("Selency 2022"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "bottom_bar_font font" }, [
        _vm._v("Created with\n        "),
        _c("span", { staticStyle: { color: "#e25555" } }, [_vm._v("♥")]),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "font caption airtable_link",
          attrs: {
            href: "https://airtable.com/shrZMxziWfl48eC9L",
            target: "_blank",
          },
        },
        [_vm._v("You have a question?")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }