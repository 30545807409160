var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "Panel",
        [
          _c("DataTable", {
            attrs: { data: _vm.data, cols: _vm.cols, error: _vm.handleError },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function (col) {
                  return [
                    _c("div", { staticClass: "status" }, [
                      _c("span", {
                        staticClass: "status-dot",
                        class: `status-dot-${col.data}`,
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.c.orderProductStatuses[col.data] || col.data
                          )
                        ),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "seller",
                fn: function (col) {
                  return [
                    _c(
                      "div",
                      { staticClass: "seller" },
                      [_c("User", { attrs: { user: col.data } })],
                      1
                    ),
                  ]
                },
              },
              {
                key: "manager",
                fn: function (col) {
                  return [
                    _c("User", {
                      attrs: { user: col.data, only: ["firstname"] },
                    }),
                  ]
                },
              },
              {
                key: "created_at",
                fn: function (col) {
                  return [_c("Date", { attrs: { value: col.data } })]
                },
              },
              {
                key: "product",
                fn: function (col) {
                  return [
                    _c(
                      "div",
                      { staticClass: "product" },
                      [
                        _c("Product", {
                          attrs: { product: col.data, max: 40 },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "price",
                fn: function (col) {
                  return [_c("Price", { attrs: { price: col.data } })]
                },
              },
              {
                key: "actions",
                fn: function (data) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "button-icon",
                        attrs: {
                          to: `/orders/${data.row.order_id}/sales/${data.row.id}`,
                        },
                      },
                      [_c("span", [_vm._v("➜")])]
                    ),
                  ]
                },
              },
              {
                key: "pagination",
                fn: function (pagination) {
                  return [
                    _c("div", { staticClass: "data-table-pagination" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("span", [_vm._v("affichage de ")]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(pagination.size))]),
                        _vm._v(" "),
                        _c("span", [_vm._v(" résultats sur ")]),
                        _vm._v(" "),
                        _c("span", [
                          pagination.total === Infinity
                            ? _c(
                                "span",
                                {
                                  attrs: { title: "Un très très grand nombre" },
                                },
                                [_vm._v("♾️")]
                              )
                            : pagination.total === undefined
                            ? _c("span", [
                                _c("i", [_vm._v("un nombre bien mystérieux")]),
                              ])
                            : _c("span", [_vm._v(_vm._s(pagination.total))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        pagination.hasBack()
                          ? _c(
                              "button",
                              {
                                staticClass: "button-secondary",
                                on: { click: pagination.back },
                              },
                              [_vm._v("Précédent")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        pagination.hasNext()
                          ? _c(
                              "button",
                              {
                                staticClass: "button-secondary",
                                on: { click: pagination.next },
                              },
                              [_vm._v("Suivant")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "loading-status",
                fn: function (status) {
                  return [
                    _c("div", { staticClass: "data-table-loading-status" }, [
                      !status.errored
                        ? _c("span", { staticClass: "loading" }, [
                            _vm._v("Chargement"),
                          ])
                        : _c("span", { staticClass: "errored" }, [
                            _vm._v("Impossible de charger ces données 💥"),
                          ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }