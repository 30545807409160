var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("status-selector", {
    attrs: { entity: _vm.cashoutPayment, states: _vm.states },
    on: { input: _vm.input },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }