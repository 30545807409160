var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.auth.logged
      ? _c("div", [_c("Login")], 1)
      : _c(
          "div",
          { staticClass: "container-bo" },
          [
            _c("Sidebar"),
            _vm._v(" "),
            !_vm.connection.online
              ? _c("Error", {
                  attrs: {
                    icon: "👴",
                    text: "Il semblerait que vous ne soyez pas connecté à internet",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.connection.online
              ? _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _vm.navigation.currentType === "new-page"
                      ? _c("router-view")
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ModalView"),
                    _vm._v(" "),
                    _vm.navigation.currentType === "legacy-frame"
                      ? _c("LegacyFrame", {
                          attrs: { page: _vm.navigation.current },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("Unicorn"),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }