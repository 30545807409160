<template>
  <div class="wrapper">
    <div v-if="order">
      <div class="container-bo">
        <div class="flex-1">
          <h1>Vente {{order.order_number}} / {{orderProduct.product.sku}}</h1>
        </div>
        <Panel class="flex-1">
          <Tags :list="order.tags" :editable="true" @state="updateTags"></Tags>
        </Panel>
        <Panel class="flex-1">
          <AirtableButton table="payments" :prefill="airtablePrefill"></AirtableButton>
          <AirtableButton table="logistics" :prefill="airtableLogisticsPrefill"></AirtableButton>
        </Panel>
      </div>
      <div class="container-bo">
        <div class="flex-1">
          <h2>Vendeur</h2>
          <div class="container-bo">
            <Panel>
              <User :user="seller" :full="true"></User>
              <br>
              <div>
                <b>Facturation TVA</b>
                <span>{{vatBillingStatus}}</span>
              </div>
              <div>
                <b>Commission initiale (TTC)</b>
                <span>{{baseCommission || 'N/A'}}</span>
              </div>
              <div>
                <b>Commission actuelle (TTC)</b>
                <span>{{currentCommission || 'N/A'}}</span>
              </div>
              <div v-if="orderProduct.invoice">
                <b>Facture</b>
                <span>{{orderProduct.invoice.invoice_number || 'N/A'}}</span>
              </div>
              <div>
                <b>Manager</b>
                <User :user="manager" :only="['firstname']"></User>
              </div>
              <div v-if="shouldDisplayBadge">
                <b>Badge</b>
                <Badge :badgeLevel="orderProduct.seller_badge_level" />
              </div>
            </Panel>
            <Panel :adminNote="pickupAddress.address_id">
              <h3>🚚 Adresse de pickup</h3>
              <Address :address="pickupAddress"></Address>
            </Panel>
          </div>
        </div>
        <div class="flex-1">
          <h2>Acheteur</h2>
          <div class="container-bo">
            <Panel class="flex-1">
              <User :user="customer" :full="true"></User>
            </Panel>
            <Panel class="flex-1" :adminNote="order.shipping_address.address_id">
              <h3>🚚 Adresse de livraison</h3>
              <Address :address="order.shipping_address"></Address>
            </Panel>
            <Panel class="flex-1" :adminNote="order.billing_address.address_id">
              <h3>🧾 Adresse de facturation</h3>
              <Address :address="order.billing_address"></Address>
            </Panel>
          </div>
        </div>
      </div>
      <Panel>
        <OrderProducts
          :list="[orderProduct]"
          :actions="['view-order', 'update-delivery', 'download-label']"></OrderProducts>
      </Panel>

      <Panel v-if="orderProduct.split">
        <h2>📑 Splits</h2>
        <SplitEditionTable :orderProduct="orderProduct"></SplitEditionTable>
      </Panel>

      <Panel>
        <h2>🚛 Livraison</h2>
        <OrderEntryDelivery
            :deliveryId="orderProduct.delivery.id"
        >
        </OrderEntryDelivery>
      </Panel>

      <Panel v-if="orderProduct.delivery.delivery_offer.provider_type === 'BROCANTE_LAB'">
        <SelencyDeliveryInfo :shippingTool="shippingTool" :delivery="orderProduct.delivery"/>
      </Panel>

      <Panel v-if="orderProduct.delivery.delivery_offer.provider_type === 'COLISSIMO'">
        <ColissimoDeliveryInfo :deliveryId="orderProduct.delivery.id" :shippingAddress="order.shipping_address"/>
      </Panel>
      <Panel v-if="orderProduct.delivery.delivery_offer.provider_type === 'COCOLIS'">
        <CocolisDeliveryInfo :deliveryId="orderProduct.delivery.id" :shippingAddress="order.shipping_address"/>
      </Panel>

      <EarlyAccess :decorate="true">
        <Panel>
          <money-flow-table
            :orderId="this.order.id"
            :orderProductId="this.orderProduct.id"></money-flow-table>
        </Panel>
      </EarlyAccess>

      <Panel>
        <h2>💰 Virements (old)</h2>
        <CashoutPayments :list="cashoutPayments"></CashoutPayments>
      </Panel>
      <Panel>
        <h2>
          💰 Virements
          <button
            v-if="shouldDisplayManualPayoutButton"
            class="button-secondary"
            @click="createManualPayout"
          >
            Ajouter un virement manuel
          </button>
        </h2>
        <CashoutPaymentsV2
          :order-product-id="$route.params.orderProductId"
          :payouts="payouts"
          :should-display-generate-payouts-button="shouldDisplayGeneratePayoutsButton"
        />
      </Panel>
      <Panel>
        <h2>🕑 Historique</h2>
        <StatusHistory
          :history="orderProduct.history"
          :translator="e => statuses[e] || e"></StatusHistory>
      </Panel>

      <Panel>
        <h2>📝 Commentaires</h2>
        <Comments></Comments>
        <div class='global-actions'>
          <button
            class='button-secondary btn-add-comment'
            @click='addComment'
          >
            + Ajouter un commentaire
          </button>
        </div>
      </Panel>
    </div>
    <div v-else>
      <span class="loading-dots">Chargement</span>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  padding: 20px 50px;
}

.global-actions {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: right;
  padding-right: 30px;
}
.btn-add-comment {
    padding: 16px 33px;
    font-weight: 600;
}
</style>

<script>
import * as orders from '../../api/orders';
import * as deliveries from '../../api/delivery';
import OrderProducts from '../OrderProducts.vue';
import CashoutPayments from './CashoutPayments.vue';
import CashoutPaymentsV2 from './CashoutPaymentsV2.vue';
import SplitEditionTable from './SplitEditionTable.vue';
import AddCashoutModal from './AddCashoutModal.vue';
import AddCashoutModalV2 from './AddCashoutModalV2.vue';
import MoneyFlowTable from '../../money-flow/MoneyFlowTable.vue';
import SelencyDeliveryInfo from '../SelencyDeliveryInfo.vue';
import ColissimoDeliveryInfo from '../ColissimoDeliveryInfo.vue';
import CocolisDeliveryInfo from '../CocolisDeliveryInfo.vue';
import constants from '../../constants';
import * as day from 'dayjs';
import Comments from '../../core/Comments.vue'
import AddCommentModal from './AddCommentModal.vue'
import ViewCommentModal from './ViewCommentModal.vue'
import Mousetrap from 'mousetrap';
import Badge from '../../domain/shop/Badge.vue';
import { hasRole } from '../../auth';
import OrderEntryDelivery from "./OrderEntryDelivery.vue";

export default {
  data() {
    return {
      statuses: constants.orderProductStatuses,
      order: null,
      orderProduct: null,
      shippingTool: null,
      shopOrder: null,
      payouts: [],
    }
  },

  methods: {
    addCashout() {
      this.$modal.show('add-cashout', {orderProduct: this.orderProduct});
    },
    createManualPayout() {
      this.$modal.show('add-cashout-v2', { orderProduct: this.orderProduct, payouts: this.payouts });
    },
    addComment() {
      this.$modal.show('add-comment', {orderId: this.order.id, orderProductId: this.orderProduct.id});
    },
    updateTags(list) {
      orders
      .updateOrder(this.order.id, {
        tags: list,
      })
      .catch(e => {
        console.log(e);
      });
    },
  },

  computed: {
    airtablePrefill() {
      return {
        'N° Commande': this.order.order_number,
        'Lien BO': window.location.href,
      };
    },

    airtableLogisticsPrefill() {
      return {
        'Order Num': this.order.order_number,
        'Request date': day().format('D/M'),
        'SKU': this.orderProduct.product.sku,
      };
    },

    pickupAddress() {
      try {
        return this.orderProduct.product.delivery_option_set.pickup_address;
      } catch(e) {
        return
      }
    },

    seller() {
      return this.orderProduct.product.created_by;
    },

    manager() {
      return this.orderProduct.product.shop.account_manager;
    },

    customer() {
      return this.order.created_by;
    },

    cashoutPayments() {
      return this
        .orderProduct
        .cashout_payments.concat(this.orderProduct.delivery.cashout_payments);
    },

    baseCommission() {
      if (!this.shopOrder) {
        return;
      }

      return `${(this.shopOrder.commission * 100).toFixed(2)}%`;
    },

    currentCommission() {
      if (!this.orderProduct.split) {
        return;
      }

      const commission = this.orderProduct.split.marketplace.value / this.orderProduct.price.value * 100;
      return `${commission.toFixed(2)}%`;
    },

    vatBillingStatus() {
      if (!this.shopOrder) {
        return;
      }

      return this.shopOrder.vat_billing_status;
    },
    shouldDisplayGeneratePayoutsButton() {
      return !this.payouts.length && this.orderProduct.status.toLowerCase() === 'delivered';
    },
    shouldDisplayManualPayoutButton() {
      const allowedStatuses = ['delivered', 'payouts-requested', 'payouts-in-transit'];
      const isUserAllowed = hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_FINANCE')

      return isUserAllowed && allowedStatuses.includes(this.orderProduct.status.toLowerCase());
    },
    isPro() {
      return this.orderProduct.product.shop.shop_type === 'PROFESSIONAL'
    },
    shouldDisplayBadge() {
      return this.isPro && this.orderProduct.seller_badge_level !== null;
    }
  },

  mounted() {
    this.$modal.register('add-cashout', AddCashoutModal);
    this.$modal.register('add-cashout-v2', AddCashoutModalV2);
    this.$modal.register('add-comment', AddCommentModal);
    this.$modal.register('view-comment', ViewCommentModal);

    Mousetrap.bind(['shift+space'], () => {
      this.$modal.show('add-comment', {orderId: this.order.id, orderProductId: this.orderProduct.id});
    });

    this.$root.$on('comment-added', () => {
      this.$store.dispatch('loadComments', {orderId: this.order.id, orderProductId: this.orderProduct.id})
    });


    const id = this.$route.params.orderId;

    const orderProductId = this.$route.params.orderProductId;

    orders
    .getOrder(id)
    .then(order => {
      this.order = order;

      orders.getPayouts(orderProductId).then((payouts) => {
        this.payouts = payouts;
      });

      const orderProducts = order.products.filter(e => e.id === orderProductId);
      this.orderProduct = orderProducts[0];

      this.shopOrder = this.order.shop_orders.filter(e => {
        return e.products.filter(e => e.id === orderProductId).length > 0;
      })[0];

      if (this.orderProduct.delivery.delivery_offer.provider_type === 'BROCANTE_LAB') {
        deliveries.getShippingTool(this.orderProduct.delivery.id)
        .then(res => {
          if (Array.isArray(res) && res.length > 0) {
            this.shippingTool = res[0];
          }
        });
      }

     this.$store.dispatch('loadComments', {orderId: this.order.id, orderProductId: this.orderProduct.id})
    });
  },

  components: {
    OrderEntryDelivery,
    Comments,
    OrderProducts,
    CashoutPayments,
    CashoutPaymentsV2,
    SplitEditionTable,
    MoneyFlowTable,
    SelencyDeliveryInfo,
    ColissimoDeliveryInfo,
    CocolisDeliveryInfo,
    Badge
  },
}
</script>
