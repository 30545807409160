var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "new-split-table" }, [
    _vm.type !== "service"
      ? _c("div", { staticClass: "flex-1 title" }, [
          _c("h3", [_vm._v(_vm._s(_vm.title) + " ")]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "service"
      ? _c("div", { staticClass: "flex-1 title" }, [
          _c(
            "h3",
            [
              _c("order-service", {
                attrs: { service: _vm.entity, price: false },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-3" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.fields, function (field, name) {
          return _c(
            "div",
            { key: `inline-box-${name}`, staticClass: "table-row" },
            [
              _c("div", { staticClass: "row repartition" }, [
                _vm._v("\n          " + _vm._s(field.title) + "\n        "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row initial", class: `${name}` }, [
                field.initial
                  ? _c("span", [
                      _vm._v(
                        _vm._s(field.initial.value) +
                          " " +
                          _vm._s(field.initial.currency)
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row refund" }, [
                field.refunds
                  ? _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.form.refunds[`${name}`].value,
                            expression: "form.refunds[`${name}`].value",
                            modifiers: { number: true },
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.refunds[`${name}`].value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form.refunds[`${name}`],
                              "value",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row post_refund", class: `${name}` }, [
                _vm._v(
                  "\n          " +
                    _vm._s(field.total.value) +
                    " " +
                    _vm._s(field.total.currency) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              (field.discounts || []).length > 0 &&
              field.discounts[0] &&
              _vm.displayDetails
                ? _c(
                    "div",
                    { staticClass: "row details" },
                    _vm._l(field.discounts, function (discount, key) {
                      return _c(
                        "div",
                        { key: `discount-${key}`, staticClass: "row" },
                        [
                          _c("b", { staticClass: "title" }, [
                            _vm._v(
                              "- " +
                                _vm._s(
                                  discount.comment ||
                                    _vm.translate(
                                      "FORM.ORDER_PRODUCT." + discount.type
                                    )
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "price" }, [
                            _vm._v(
                              _vm._s(discount.refund.value) +
                                " " +
                                _vm._s(discount.refund.currency)
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-row header" }, [
      _c("div", { staticClass: "row repartition" }, [
        _c("b", [_vm._v("Repartition")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row initial" }, [_c("b", [_vm._v("Initial")])]),
      _vm._v(" "),
      _c("div", { staticClass: "row refund" }, [
        _c("b", [_vm._v("Remboursements")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row post_refund" }, [
        _c("b", [_vm._v("Post Remb.")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }