var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-md-6" }, [
        _c("div", { staticClass: "bg-color-grey" }, [
          _c("p", { staticClass: "label inline-block" }, [
            _vm._v("\n                    Acheteur\n                "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "label inline-block price-right" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.getSplitBuyer()) +
                " €\n                "
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-md-6" }, [
        _c("div", [
          _c("p", { staticClass: "label inline-block" }, [
            _vm._v("\n                    Vendeur\n                "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "label inline-block price-right" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.getSplitSeller()) +
                " €\n                "
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-md-6" }, [
        _c("div", { staticClass: "bg-color-grey" }, [
          _c("p", { staticClass: "label inline-block" }, [
            _vm._v("\n                    Selency\n                "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "label inline-block price-right" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.getSplitMarketPlace()) +
                " €\n                "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row margin-top-update-delivery" }, [
      _c("div", { staticClass: "col-xs-12 col-md-6" }, [
        _c("h4", [_vm._v("Split")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }