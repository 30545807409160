var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h2", [_vm._v("🚚 Livraison Selency ")]),
    _vm._v(" "),
    !_vm.shippingTool
      ? _c("div", { staticClass: "row" }, [
          _c(
            "p",
            [
              _vm.isHandledByUrbantz
                ? _c("span", [
                    _c("b", [_vm._v("🚚 Géré par:")]),
                    _vm._v(" Urbantz "),
                    _c("br"),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "cars" } }, [
                      _vm._v("Changer de shipping service :"),
                    ]),
                    _vm._v(" "),
                    _c("i", [
                      _vm._v(
                        "changer de shipping service va supprimer les tâches urbantz et créer une ligne dans le shipping-tool"
                      ),
                    ]),
                    _c("br"),
                    _vm._v(" "),
                    _c("input", {
                      staticStyle: { margin: "6px 0 12px 0" },
                      attrs: {
                        type: "submit",
                        value: "Changer de shipping service",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateShippingServiceForShippingTool()
                        },
                      },
                    }),
                  ])
                : _c("span", [
                    _vm._v(
                      "Non renseignée dans les services de livraisons (shipping tool, urbantz)"
                    ),
                  ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("b", [_vm._v("🚚 Transporteur :")]),
              _vm._v(" "),
              _vm.shipper
                ? [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.shipperDisplayName) + " "),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "cars" } }, [
                      _vm._v("Changer de transporteur :"),
                    ]),
                    _vm._v(" "),
                    _c("i", [
                      _vm._v(
                        "changer le transporteur de cette commande va supprimer dans le service de livraison toutes les informations de logistique actuelles (dates d'enlèvement et de livraison, commentaires du transporteur actuel), puis recréer une tâche avec votre nouveau transporteur et les informations de la commande"
                      ),
                    ]),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedShipper,
                            expression: "selectedShipper",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedShipper = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option"),
                        _vm._v(" "),
                        _vm._l(_vm.availableShippers, function (shipper, key) {
                          return _c(
                            "option",
                            { key: key, domProps: { value: shipper } },
                            [_vm._v(_vm._s(shipper.toUpperCase()))]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticStyle: { "margin-left": "12px" },
                      attrs: {
                        type: "submit",
                        value: "Changer de transporteur",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateShipper()
                        },
                      },
                    }),
                  ]
                : _c("span", [_vm._v("❌ Non affecté")]),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.shippingTool
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-5 col-xs-offset-2" }, [
            _c("h3", [_vm._v("Pickup")]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("🔎 Issue :")]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.getShippingToolIssueLink(
                      _vm.shippingTool.pickup.issue_id
                    ),
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n                      " +
                      _vm._s(_vm.shippingTool.pickup.issue_id) +
                      "\n                  "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("ℹ️ Statut :")]),
              _vm._v(" " + _vm._s(_vm.shippingTool.pickup.status)),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("🚚 Transporteur :")]),
              _vm._v(" "),
              _vm.shippingTool.pickup.service
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.shippingTool.pickup.service) + " "),
                  ])
                : _c("span", [_vm._v("❌ Non affecté")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-5" }, [
            _c("h3", [_vm._v("Delivery")]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("🔎 Issue :")]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.getShippingToolIssueLink(
                      _vm.shippingTool.shipping.issue_id
                    ),
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n                      " +
                      _vm._s(_vm.shippingTool.shipping.issue_id) +
                      "\n                  "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("ℹ️ Statut :")]),
              _vm._v(" " + _vm._s(_vm.shippingTool.shipping.status)),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("b", [_vm._v("🚚 Transporteur :")]),
              _vm._v(" "),
              _vm.shippingTool.shipping.service
                ? _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.shippingTool.shipping.service) + " "
                    ),
                  ])
                : _c("span", [_vm._v("❌ Non affecté")]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.shippingTool
      ? _c("div", { staticClass: "row" }, [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-xs-10 col-xs-offset-1" }, [
      _c("hr"),
      _vm._v(" "),
      _c("h3", [_vm._v("Notes :")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "\n                      Remplacer ou annuler une livraison selency peut avoir un impact sur l'envoi des mails ou la frise dans l'interface marchand.\n                      Veuillez vérifier que les issues listées ci-dessus correspondent bien aux positions utilisées actuellement.\n                  "
          ),
        ]),
        _vm._v(" "),
        _c("li", [_c("i", [_vm._v("D'autres infos sont à venir ici...")])]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }