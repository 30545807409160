var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _vm.order
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "container-bo" },
              [
                _c("div", { staticClass: "flex-1" }, [
                  _c("h1", [
                    _vm._v(
                      "Vente " +
                        _vm._s(_vm.order.order_number) +
                        " / " +
                        _vm._s(_vm.orderProduct.product.sku)
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "Panel",
                  { staticClass: "flex-1" },
                  [
                    _c("Tags", {
                      attrs: { list: _vm.order.tags, editable: true },
                      on: { state: _vm.updateTags },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "Panel",
                  { staticClass: "flex-1" },
                  [
                    _c("AirtableButton", {
                      attrs: {
                        table: "payments",
                        prefill: _vm.airtablePrefill,
                      },
                    }),
                    _vm._v(" "),
                    _c("AirtableButton", {
                      attrs: {
                        table: "logistics",
                        prefill: _vm.airtableLogisticsPrefill,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "container-bo" }, [
              _c("div", { staticClass: "flex-1" }, [
                _c("h2", [_vm._v("Vendeur")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "container-bo" },
                  [
                    _c(
                      "Panel",
                      [
                        _c("User", { attrs: { user: _vm.seller, full: true } }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", [
                          _c("b", [_vm._v("Facturation TVA")]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.vatBillingStatus))]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("b", [_vm._v("Commission initiale (TTC)")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.baseCommission || "N/A")),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("b", [_vm._v("Commission actuelle (TTC)")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.currentCommission || "N/A")),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.orderProduct.invoice
                          ? _c("div", [
                              _c("b", [_vm._v("Facture")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderProduct.invoice.invoice_number ||
                                      "N/A"
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("b", [_vm._v("Manager")]),
                            _vm._v(" "),
                            _c("User", {
                              attrs: { user: _vm.manager, only: ["firstname"] },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.shouldDisplayBadge
                          ? _c(
                              "div",
                              [
                                _c("b", [_vm._v("Badge")]),
                                _vm._v(" "),
                                _c("Badge", {
                                  attrs: {
                                    badgeLevel:
                                      _vm.orderProduct.seller_badge_level,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "Panel",
                      { attrs: { adminNote: _vm.pickupAddress.address_id } },
                      [
                        _c("h3", [_vm._v("🚚 Adresse de pickup")]),
                        _vm._v(" "),
                        _c("Address", {
                          attrs: { address: _vm.pickupAddress },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-1" }, [
                _c("h2", [_vm._v("Acheteur")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "container-bo" },
                  [
                    _c(
                      "Panel",
                      { staticClass: "flex-1" },
                      [
                        _c("User", {
                          attrs: { user: _vm.customer, full: true },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "Panel",
                      {
                        staticClass: "flex-1",
                        attrs: {
                          adminNote: _vm.order.shipping_address.address_id,
                        },
                      },
                      [
                        _c("h3", [_vm._v("🚚 Adresse de livraison")]),
                        _vm._v(" "),
                        _c("Address", {
                          attrs: { address: _vm.order.shipping_address },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "Panel",
                      {
                        staticClass: "flex-1",
                        attrs: {
                          adminNote: _vm.order.billing_address.address_id,
                        },
                      },
                      [
                        _c("h3", [_vm._v("🧾 Adresse de facturation")]),
                        _vm._v(" "),
                        _c("Address", {
                          attrs: { address: _vm.order.billing_address },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "Panel",
              [
                _c("OrderProducts", {
                  attrs: {
                    list: [_vm.orderProduct],
                    actions: [
                      "view-order",
                      "update-delivery",
                      "download-label",
                    ],
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.orderProduct.split
              ? _c(
                  "Panel",
                  [
                    _c("h2", [_vm._v("📑 Splits")]),
                    _vm._v(" "),
                    _c("SplitEditionTable", {
                      attrs: { orderProduct: _vm.orderProduct },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "Panel",
              [
                _c("h2", [_vm._v("🚛 Livraison")]),
                _vm._v(" "),
                _c("OrderEntryDelivery", {
                  attrs: { deliveryId: _vm.orderProduct.delivery.id },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.orderProduct.delivery.delivery_offer.provider_type ===
            "BROCANTE_LAB"
              ? _c(
                  "Panel",
                  [
                    _c("SelencyDeliveryInfo", {
                      attrs: {
                        shippingTool: _vm.shippingTool,
                        delivery: _vm.orderProduct.delivery,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.orderProduct.delivery.delivery_offer.provider_type ===
            "COLISSIMO"
              ? _c(
                  "Panel",
                  [
                    _c("ColissimoDeliveryInfo", {
                      attrs: {
                        deliveryId: _vm.orderProduct.delivery.id,
                        shippingAddress: _vm.order.shipping_address,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.orderProduct.delivery.delivery_offer.provider_type === "COCOLIS"
              ? _c(
                  "Panel",
                  [
                    _c("CocolisDeliveryInfo", {
                      attrs: {
                        deliveryId: _vm.orderProduct.delivery.id,
                        shippingAddress: _vm.order.shipping_address,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "EarlyAccess",
              { attrs: { decorate: true } },
              [
                _c(
                  "Panel",
                  [
                    _c("money-flow-table", {
                      attrs: {
                        orderId: this.order.id,
                        orderProductId: this.orderProduct.id,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "Panel",
              [
                _c("h2", [_vm._v("💰 Virements (old)")]),
                _vm._v(" "),
                _c("CashoutPayments", { attrs: { list: _vm.cashoutPayments } }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "Panel",
              [
                _c("h2", [
                  _vm._v("\n        💰 Virements\n        "),
                  _vm.shouldDisplayManualPayoutButton
                    ? _c(
                        "button",
                        {
                          staticClass: "button-secondary",
                          on: { click: _vm.createManualPayout },
                        },
                        [
                          _vm._v(
                            "\n          Ajouter un virement manuel\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("CashoutPaymentsV2", {
                  attrs: {
                    "order-product-id": _vm.$route.params.orderProductId,
                    payouts: _vm.payouts,
                    "should-display-generate-payouts-button":
                      _vm.shouldDisplayGeneratePayoutsButton,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "Panel",
              [
                _c("h2", [_vm._v("🕑 Historique")]),
                _vm._v(" "),
                _c("StatusHistory", {
                  attrs: {
                    history: _vm.orderProduct.history,
                    translator: (e) => _vm.statuses[e] || e,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "Panel",
              [
                _c("h2", [_vm._v("📝 Commentaires")]),
                _vm._v(" "),
                _c("Comments"),
                _vm._v(" "),
                _c("div", { staticClass: "global-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button-secondary btn-add-comment",
                      on: { click: _vm.addComment },
                    },
                    [_vm._v("\n          + Ajouter un commentaire\n        ")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", [
          _c("span", { staticClass: "loading-dots" }, [_vm._v("Chargement")]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }