var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("table", { staticClass: "data-table" }, [
        _c("thead", [
          _c(
            "tr",
            [
              _vm._l(_vm.cols, function (col, name) {
                return _c(
                  "th",
                  { key: `col-${name}`, class: `col-head-${name}` },
                  [
                    _vm._t(
                      `col-head-${name}`,
                      function () {
                        return [_c("span", [_vm._v(_vm._s(col.title || name))])]
                      },
                      { config: col }
                    ),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _vm.actionable
                ? _c(
                    "th",
                    { staticClass: "col-head-actions" },
                    [
                      _vm._t(`col-head-actions`, function () {
                        return [_c("span", [_vm._v("Actions")])]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.ready && this.searchable
            ? _c(
                "tr",
                [
                  _vm._l(_vm.cols, function (col, name) {
                    return _c("th", { key: `col-search-${name}` }, [
                      col.search
                        ? _c("div", [
                            col.search.type === "text"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.search[name],
                                      expression: "search[name]",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: col.search.placeholder || "",
                                  },
                                  domProps: { value: _vm.search[name] },
                                  on: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.submit.apply(null, arguments)
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.search,
                                        name,
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            col.search.type === "select"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.search[name],
                                        expression: "search[name]",
                                      },
                                    ],
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.search,
                                          name,
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option"),
                                    _vm._v(" "),
                                    _vm._l(col.search.options, function (v, k) {
                                      return _c(
                                        "option",
                                        { key: k, domProps: { value: k } },
                                        [_vm._v(_vm._s(v))]
                                      )
                                    }),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  _vm._v(" "),
                  _vm.actionable
                    ? _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "button-text",
                            on: { click: _vm.submit },
                          },
                          [_vm._v("🔎")]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.results, function (row, index) {
              return [
                row._type !== "row-helper"
                  ? _c(
                      "tr",
                      { key: `row-${index}` },
                      [
                        _vm._l(_vm.cols, function (col, name) {
                          return _c(
                            "td",
                            {
                              key: `row-${index}-${name}`,
                              class: `col-${name}`,
                            },
                            [
                              name === "tracking" &&
                              "COCOLIS" ===
                                row.delivery.delivery_offer.provider_type
                                ? _c("div", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.cocolisUrl,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Voir\n                "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._t(
                                    name,
                                    function () {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              col.getter
                                                ? col.getter(row)
                                                : row[name] || ""
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                    {
                                      data: col.getter
                                        ? col.getter(row)
                                        : row[name] || "",
                                    }
                                  ),
                            ],
                            2
                          )
                        }),
                        _vm._v(" "),
                        _vm.actionable
                          ? _c(
                              "td",
                              { staticClass: "col-actions" },
                              [
                                _vm._t("actions", null, {
                                  row: row,
                                  helper: () => _vm.showHelper(index),
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm.shouldShowHelper(index - 1)
                  ? _c(
                      "tr",
                      { key: `row-helper-${index}`, staticClass: "row-helper" },
                      [
                        _c(
                          "td",
                          { attrs: { colspan: _vm.colspan } },
                          [
                            _vm._t(`row-helper`, null, {
                              row: row,
                              rows: _vm.results,
                              index: (index - 1) / 2,
                            }),
                          ],
                          2
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            }),
            _vm._v(" "),
            _vm.noData
              ? _c("tr", [
                  _c(
                    "td",
                    { attrs: { colspan: _vm.colspan } },
                    [
                      _vm._t("no-data", function () {
                        return [_c("span", [_vm._v("No data")])]
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      !_vm.ready
        ? _vm._t(
            "loading-status",
            function () {
              return [
                _c("div", { staticClass: "center data-table-loading-status" }, [
                  !_vm.errored
                    ? _c("div", [_c("span", [_vm._v("Loading")])])
                    : _c("div", [_c("span", [_vm._v("Error")])]),
                ]),
              ]
            },
            { ready: _vm.ready, errored: _vm.errored }
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ready && this.paginate
        ? _vm._t(
            "pagination",
            function () {
              return [
                _c("div", { staticClass: "data-table-pagination" }, [
                  _c("div", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.results.length) + " results"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { on: { click: _vm.loadPage } }, [
                      _vm._v("next"),
                    ]),
                  ]),
                ]),
              ]
            },
            {
              size: _vm.results.length,
              total: _vm.pagination.total,
              next: () => _vm.next(),
              hasNext: _vm.hasNext,
              back: () => _vm.back(),
              hasBack: _vm.hasBack,
            }
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }