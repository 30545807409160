var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "dangerous-button button-danger",
      attrs: { disabled: _vm.disabled, state: _vm.state },
      on: { click: _vm.click },
    },
    [
      _vm.state === 0
        ? _vm._t("default", function () {
            return [_c("span", [_vm._v("Confirmer")])]
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.state === 1 || (_vm.state === 2 && !_vm.timeout)
        ? _vm._t("confirm", function () {
            return [
              _c("span", [_vm._v(_vm._s(_vm.textConfirm || "Vraiment ?"))]),
            ]
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.state === 2 && _vm.timeout
        ? _vm._t("timeout", function () {
            return [
              _c("span", [_vm._v(_vm._s(_vm.textTimeout || "Un instant..."))]),
            ]
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.state === 3
        ? _vm._t("done", function () {
            return [_c("span", [_vm._v(_vm._s(_vm.textDone || "Effectué"))])]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }