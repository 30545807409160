var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "modal", staticClass: "modal-backdrop" }, [
    _c(
      "div",
      { staticClass: "modal-content-wrapper" },
      [
        _c(
          "Panel",
          [
            _c("div", { staticClass: "modal-close" }, [
              _c("span", { on: { click: _vm.close } }, [_vm._v("Fermer")]),
            ]),
            _vm._v(" "),
            _vm._t("panel-content"),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }