var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Panel",
        [
          _vm._t("header"),
          _vm._v(" "),
          _c("DataTable", {
            attrs: {
              cols: _vm.cols,
              data: _vm.getData,
              paginate: false,
              searchable: false,
            },
            scopedSlots: _vm._u([
              {
                key: "col-head-price",
                fn: function () {
                  return [
                    _c("span", [_vm._v("Prix")]),
                    _vm._v(" "),
                    _c("Tip", {
                      attrs: {
                        text: "Prix initial, hors code promos, turbos et remboursements",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "status",
                fn: function (col) {
                  return [
                    _c("order-product-status", {
                      attrs: { orderProduct: col.data },
                      model: {
                        value: _vm.form.status[col.data.id],
                        callback: function ($$v) {
                          _vm.$set(_vm.form.status, col.data.id, $$v)
                        },
                        expression: "form.status[col.data.id]",
                      },
                    }),
                    _vm._v(" "),
                    _vm.shouldDisplayCancelOrderEntryButton(col.data)
                      ? _c(
                          "button",
                          {
                            staticClass: "button-secondary",
                            on: {
                              click: function ($event) {
                                return _vm.cancelOrderEntry(col.data)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            Annuler (le produit et les payouts)\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldDisplayRequestPayoutsButton(col.data)
                      ? _c(
                          "button",
                          {
                            staticClass: "button-secondary",
                            on: {
                              click: function ($event) {
                                return _vm.requestPayouts(col.data)
                              },
                            },
                          },
                          [
                            _vm._v("\n            Demander les virements "),
                            _c("br"),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("(⚠️ veillez à d'abord les générer !)"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldDisplayRestoreOrderEntryButton(col.data)
                      ? _c(
                          "button",
                          {
                            staticClass: "button-secondary",
                            on: {
                              click: function ($event) {
                                return _vm.restoreOrderEntry(col.data)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            Restaurer (le produit et les payouts)\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "product",
                fn: function (col) {
                  return [_c("Product", { attrs: { product: col.data } })]
                },
              },
              {
                key: "price",
                fn: function (col) {
                  return [
                    _c("Price", { attrs: { price: col.data } }),
                    _vm._v(" "),
                    _c("br"),
                  ]
                },
              },
              {
                key: "quantity",
                fn: function (col) {
                  return [_c("span", [_vm._v(_vm._s(col.data.quantity))])]
                },
              },
              {
                key: "asProPrice",
                fn: function (col) {
                  return [
                    col.data.as_pro_price && col.data.as_pro_price
                      ? _c("p", { staticStyle: { color: "#7baa86" } }, [
                          _vm._v(" Prix pro 💼"),
                        ])
                      : _c("p", [_vm._v(" -  ")]),
                  ]
                },
              },
              {
                key: "tracking",
                fn: function (col) {
                  return [_c("TrackingCode", { attrs: { delivery: col.data } })]
                },
              },
              {
                key: "delivery",
                fn: function (col) {
                  return [_c("Delivery", { attrs: { delivery: col.data } })]
                },
              },
              {
                key: "services",
                fn: function (col) {
                  return [
                    col.data.length
                      ? _vm._l(col.data, function (service) {
                          return _c("OrderService", {
                            key: service.id,
                            attrs: { service: service },
                          })
                        })
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
              {
                key: "seller",
                fn: function (col) {
                  return [_c("User", { attrs: { user: col.data } })]
                },
              },
              {
                key: "actions",
                fn: function (data) {
                  return [
                    _vm.showAction("update-status", data.row)
                      ? _c(
                          "DangerousButton",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.updateStatus(data.row.id)
                              },
                            },
                          },
                          [_vm._v("Mettre à jour")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shouldDisplayRevertStatusButton(data.row.id) &&
                    _vm.showAction("cancel-abort", data.row)
                      ? _c(
                          "DangerousButton",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.revertStatus(data.row.id)
                              },
                            },
                          },
                          [_vm._v("Annuler l'annulation")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showAction("download-label", data.row)
                      ? _c(
                          "a",
                          {
                            staticClass: "button-secondary",
                            attrs: {
                              href: _vm.labelLink(data.row),
                              target: "_blank",
                            },
                          },
                          [_vm._v("Télécharger l'étiquette")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button-secondary",
                        on: {
                          click: function ($event) {
                            return _vm.display({
                              view: "option_section",
                              data: data.row,
                            })
                          },
                        },
                      },
                      [_vm._v("Details")]
                    ),
                    _vm._v(" "),
                    _vm.showAction("view-sale")
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: `/orders/${data.row.order_id}/sales/${data.row.id}`,
                            },
                          },
                          [_vm._v("Vue vendeur")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showAction("view-order")
                      ? _c(
                          "router-link",
                          { attrs: { to: `/orders/${data.row.order_id}` } },
                          [_vm._v("Vue acheteur")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.canDisplay("option_section")
        ? _c("div", { staticClass: "wrapper" }, [
            _c("div", { staticClass: "container-bo option-section" }, [
              _c("div", { staticClass: "flex-1" }, [
                _c(
                  "div",
                  { staticClass: "container-bo" },
                  [
                    _c(
                      "Panel",
                      { staticClass: "order-actions" },
                      [
                        _c("h4", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.productTitle) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.selectedOrderProduct.product.sku) +
                              "\n\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "button-primary flex-1",
                            on: {
                              click: function ($event) {
                                return _vm.updateDeliveryOrderProduct(
                                  "update-delivery-order-product",
                                  _vm.selectedOrderProduct
                                )
                              },
                            },
                          },
                          [_vm._v("MODIFIER LA LIVRAISON")]
                        ),
                        _vm._v(" "),
                        _vm.selectedOrderProduct.status === "STAND_BY"
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "button-primary background-stand-by flex-1",
                                on: {
                                  click: function ($event) {
                                    return _vm.revertOrderProductStatus(
                                      _vm.selectedOrderProduct.id
                                    )
                                  },
                                },
                              },
                              [_vm._v("RELANCER LA COMMANDE")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "button-danger flex-1",
                            on: {
                              click: function ($event) {
                                return _vm.refund(
                                  "new-refund",
                                  _vm.selectedOrderProduct
                                )
                              },
                            },
                          },
                          [_vm._v("REMBOURSER")]
                        ),
                        _vm._v(" "),
                        _vm.selectedOrderProduct.status !== "ABORTED"
                          ? _c(
                              "span",
                              {
                                staticClass: "button-danger flex-1",
                                on: {
                                  click: function ($event) {
                                    return _vm.refund(
                                      "new-cancel-order",
                                      _vm.selectedOrderProduct
                                    )
                                  },
                                },
                              },
                              [_vm._v("ANNULER LA COMMANDE")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("AirtableButton", {
                          attrs: {
                            table: "logistics",
                            prefill: _vm.airtableLogisticsPrefill,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "Panel",
                      { staticClass: "flex-3 order-history" },
                      [
                        _c("money-flow-table", {
                          attrs: {
                            orderId: _vm.selectedOrderProduct.order_id,
                            orderProductId: _vm.selectedOrderProduct.id,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.seller
                      ? _c(
                          "Panel",
                          { staticClass: "flex-1 order-seller-infos" },
                          [
                            _c("User", {
                              attrs: { user: _vm.seller, full: true },
                            }),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("div", [
                              _c("b", [_vm._v("Commission initiale (TTC)")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.baseCommission || "N/A")),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("b", [_vm._v("Facturation TVA")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.vatBillingStatus)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("b", [_vm._v("Commission actuelle (TTC)")]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.currentCommission || "N/A")),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("b", [_vm._v("Manager")]),
                                _vm._v(" "),
                                _c("User", {
                                  attrs: {
                                    user: _vm.manager,
                                    only: ["firstname"],
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("b", [_vm._v("🚚 Adresse de pickup")]),
                                _vm._v(" "),
                                _c("Address", {
                                  attrs: { address: _vm.pickupAddress },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }