var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    { staticClass: "button-secondary", on: { click: _vm.click } },
    [
      _c("img", { attrs: { src: "/assets/img/airtable.svg", height: "16" } }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }