var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", [_vm._v("📦 Livraison Cocolis")]),
    _vm._v(" "),
    _vm.delivery
      ? _c("div", [
          _c(
            "div",
            { staticClass: "row", staticStyle: { "padding-bottom": "20px" } },
            [
              _c("div", { staticClass: "col-xs-5 col-xs-offset-1" }, [
                _c(
                  "div",
                  [
                    _c("b", [_vm._v("Packages : ")]),
                    _vm._v(" "),
                    _vm.delivery.delivery_offer.packages.length === 0
                      ? _c("span", [_vm._v("⚠️ 0 found ⚠️")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(
                      _vm.delivery.delivery_offer.packages,
                      function (pckg) {
                        return _c("div", { key: pckg }, [
                          _vm._v(
                            "\n                        -\n                        "
                          ),
                          _c("b", [_vm._v("Depth :")]),
                          _vm._v(
                            "  " +
                              _vm._s(pckg.depth) +
                              " cm\n                        "
                          ),
                          _c("b", [_vm._v("Height :")]),
                          _vm._v(
                            " " +
                              _vm._s(pckg.height) +
                              " cm\n                        "
                          ),
                          _c("b", [_vm._v("Width :")]),
                          _vm._v(
                            "  " +
                              _vm._s(pckg.width) +
                              " cm\n                    "
                          ),
                        ])
                      }
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: this.delivery.cocolis_ride.seller_tracking,
                          target: "_blank",
                        },
                      },
                      [
                        _c("button", { staticClass: "button-secondary" }, [
                          _vm._v(
                            "\n                            🔎 Suivi sur le site de Cocolis\n                        "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }