var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.loading ? _c("div", { staticClass: "background" }) : _vm._e(),
    _vm._v(" "),
    !_vm.loading
      ? _c("div", { staticClass: "login" }, [
          _c("h1", [_vm._v("Backoffice")]),
          _vm._v(" "),
          _vm.loading
            ? _c("div", [_c("span", [_vm._v("Login...")])])
            : _vm._e(),
          _vm._v(" "),
          !_vm.loading
            ? _c(
                "form",
                { attrs: { action: "#" }, on: { submit: _vm.handleLogin } },
                [
                  _c("div", { staticClass: "error" }, [
                    _vm.error
                      ? _c("span", [
                          _c("i", [_vm._v("Identifiants invalides")]),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      ref: "email",
                      attrs: {
                        type: "text",
                        name: "selency-bo-email",
                        placeholder: "Email",
                        autofocus: "",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: {
                        type: "password",
                        name: "selency-bo-password",
                        placeholder: "Mot de passe",
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm._m(0),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "button",
        { staticClass: "button-primary", attrs: { type: "submit" } },
        [_vm._v("Login")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }