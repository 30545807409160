import axios from 'axios';
import config from '../config';

const ACCEPT_HEADER = 'application/vnd.selency.v2+json';

async function getComments(orderId, orderProductId = null) {
  const url = orderProductId ? `/orders/${orderId}/comments?orderEntryId=${orderProductId}` : `/orders/${orderId}/comments`;

  try {
    const response = await axios.get(config.monolithApi(url), {
      headers: {
        Accept: ACCEPT_HEADER,
      },
    });

    return response.data;
  } catch (error) {
    console.error(`Error fetching comments: ${error}`);
    throw new Error('Error fetching comments');
  }
}

async function addComment(orderId, comment, orderProductId = null) {
  const url = orderProductId ? `/orders/${orderId}/comments` : `/orders/${orderId}/comments`;

  try {
    const response = await axios.post(
      config.monolithApi(url),
      {
        content: comment,
        orderEntryId: orderProductId,
      },
      {
        headers: {
          Accept: ACCEPT_HEADER,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(`Error adding comment: ${error}`);
    throw new Error('Error adding comment');
  }
}

async function applyCategory(categoryId, products) {
  try {
    const response = await axios.post(
        config.monolithApi(`/products/categories/${categoryId}/apply`),
        {
          products: products,
        },
        {
          headers: {
            Accept: ACCEPT_HEADER,
          },
        }
    );

    return response.data;
  } catch (error) {
    console.error(`Error on category apply: ${error}`);
    throw new Error('Error on category apply');
  }
}

async function archiveCategory(categoryId, transferCategoryId) {
  try {
    const response = await axios.post(
        config.monolithApi(`/categories/${categoryId}/archive`),
        {
          transferCategoryId: transferCategoryId,
        },
        {
          headers: {
            Accept: ACCEPT_HEADER,
          },
        }
    );

    return response.data;
  } catch (error) {
    console.error(`Error on category archive: ${error}`);
    throw new Error('Error on category archive');
  }
}

async function productDeliveryOptionActivateBrocanteLabGrid(productId, deliveryOptionSetId) {
  try {
    const response = await axios.post(
        config.monolithApi(`/legacy/products/${productId}/delivery-options`),
        {
          providerType: 'BROCANTE_LAB',
          deliveryOptionSetId: deliveryOptionSetId,
          body: []
        },
        {
          headers: {
            Accept: ACCEPT_HEADER,
          },
        }
    );

    return response.data;
  } catch (error) {
    console.error(`Error on activate BrocanteLabGrid: ${error}`);
    throw new Error('Error on activate BrocanteLabGrid');
  }
}

async function productDeliveryOptionCreate(productId, payload) {
  try {
    const response = await axios.post(
        config.monolithApi(`/legacy/products/${productId}/delivery-options`),
        payload,
        {
          headers: {
            Accept: ACCEPT_HEADER,
          },
        }
    );

    return response.data;
  } catch (error) {
    console.error(`Error on create delivery offer: ${error}`);
    throw new Error('Error on create delivery offer');
  }
}

async function updateOrderEntryDelivery(payload, deliveryId) {
        const response = await axios.patch(
            config.monolithApi(`/order-entry-deliveries/${deliveryId}`),
            payload,
            {
                headers: {
                    Accept: ACCEPT_HEADER,
                },
            }
        );

        return response.data;
}

async function getOrderEntryDelivery(deliveryId) {
    const response = await axios.get(
        config.monolithApi(`/order-entry-deliveries/${deliveryId}`),
        {
            headers: {
                Accept: ACCEPT_HEADER,
            },
        }
    );

    return response.data;
}

async function createTaskForOrderEntryDelivery(deliveryId) {
    const response = await axios.post(
        config.monolithApi(`/order-entry-deliveries/${deliveryId}/tasks`),
        {},
        {
            headers: {
                Accept: ACCEPT_HEADER,
            },
        }
    );

    return response;
}

async function getUsableDeliveryOptions(productId, queryParams) {
    const response = await axios.get(
        config.monolithApi(`/products/${productId}/usable-delivery-options`),
        {
            headers: {
                Accept: ACCEPT_HEADER,
            },
            params: queryParams
        }
    );

    return response.data.items.map(entry => entry.item);
}

async function downloadOrderEntryDeliveryNote(id) {
    return await axios.get(
        config.monolithApi('/order-entry-deliveries/' + id + '/delivery-note'),
        {
            headers: {
                Accept: ACCEPT_HEADER,
            },
            responseType: 'blob'
        }
    );
}



export {
    getComments,
    addComment,
    applyCategory,
    archiveCategory,
    productDeliveryOptionActivateBrocanteLabGrid,
    productDeliveryOptionCreate,
    updateOrderEntryDelivery,
    getOrderEntryDelivery,
    getUsableDeliveryOptions,
    createTaskForOrderEntryDelivery,
    downloadOrderEntryDeliveryNote
};
