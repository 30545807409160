var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "tip", attrs: { title: _vm.text } }, [
    _vm._v("?"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }