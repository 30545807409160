var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", [_vm._v(_vm._s(_vm.icon || "💥"))]),
    _vm._v(" "),
    _c("h2", [_vm._v(_vm._s(_vm.text || "Une erreur est survenue"))]),
    _vm._v(" "),
    _vm.details ? _c("p", [_vm._v(_vm._s(_vm.details))]) : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "help" },
      [
        _c("AirtableButton", {
          attrs: { table: "allo-la-tech", prefill: _vm.prefill },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }