var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.decorate && _vm.allowed ? "early-wrapper" : "" },
    [
      _c("div", { staticClass: "early-label" }, [
        _c("span", [_vm._v("accès anticipé pour " + _vm._s(_vm.name))]),
      ]),
      _vm._v(" "),
      _vm.allowed ? _vm._t("default") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }