var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _vm.order
      ? _c(
          "div",
          [
            _c("div", { staticClass: "container-bo" }, [
              _c("h1", { staticClass: "flex-1" }, [
                _vm._v("Commande " + _vm._s(_vm.order.order_number)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container-bo flex-1" },
                [
                  _c(
                    "Panel",
                    { staticClass: "flex-1" },
                    [
                      _c("Tags", {
                        attrs: { list: _vm.order.tags, editable: true },
                        on: { state: _vm.updateTags },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "Panel",
                    { staticClass: "flex-1" },
                    [
                      _c("AirtableButton", {
                        attrs: {
                          table: "payments",
                          prefill: _vm.airtablePrefill,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "container-bo" }, [
              _c("div", { staticClass: "flex-1" }, [
                _c(
                  "div",
                  { staticClass: "container-bo order-infos" },
                  [
                    _c(
                      "Panel",
                      { staticClass: "flex-1" },
                      [
                        _c("span", [_c("b", [_vm._v("Statut")])]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.statuses[_vm.order.status] || _vm.order.status
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", [_c("b", [_vm._v("Date")])]),
                        _vm._v(" "),
                        _c("Date", { attrs: { value: _vm.order.created_at } }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", [_c("b", [_vm._v("Source")])]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.order.source))]),
                        _vm._v(" "),
                        _c("h3", [_vm._v("Acheteur")]),
                        _vm._v(" "),
                        _c("User", {
                          attrs: { user: _vm.customer, full: true },
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _c("br"),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "Panel",
                      { staticClass: "flex-1" },
                      [
                        _c("h3", [_vm._v("🚚 Adresse de livraison")]),
                        _vm._v(" "),
                        _vm.order.shipping_address
                          ? _c("Address", {
                              attrs: { address: _vm.order.shipping_address },
                            })
                          : _c("span", [_vm._v("N/A")]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "Panel",
                      { staticClass: "flex-1" },
                      [
                        _c("h3", [_vm._v("🧾 Adresse de facturation")]),
                        _vm._v(" "),
                        _vm.order.billing_address
                          ? _c("Address", {
                              attrs: { address: _vm.order.billing_address },
                            })
                          : _c("span", [_vm._v("N/A")]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.order.fee != null
                      ? _c(
                          "Panel",
                          { staticClass: "flex-1" },
                          [
                            _c("h3", [_vm._v("Frais de service")]),
                            _vm._v(" "),
                            _c("span", [_c("b", [_vm._v("Montant : ")])]),
                            _vm._v(_vm._s(_vm.order.fee.price.value) + "€"),
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _vm.order.fee.refunds.length === 0
                              ? _c(
                                  "DangerousButton",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.refundFee(_vm.order)
                                      },
                                    },
                                  },
                                  [_vm._v("Rembourser")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.order.fee.refunds.length > 0
                              ? _c("span", [
                                  _vm._v("Frais de service remboursés"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("OrderProducts", {
              attrs: {
                list: _vm.order.products,
                "shop-orders": _vm.order.shop_orders,
                order: _vm.order,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "h2",
                          [
                            _c("span", [_vm._v("Récap commande - total")]),
                            _vm._v(" "),
                            _c("Prices", {
                              attrs: {
                                list: _vm.order.prices,
                                only: "total_price_current",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                692749394
              ),
            }),
            _vm._v(" "),
            _vm.showPaymentRequests
              ? _c("Panel", [
                  _c("h2", [_vm._v("Demandes de paiement")]),
                  _vm._v(" "),
                  _vm.order.payment_requests.length
                    ? _c(
                        "div",
                        [
                          _c("span", [_vm._v("Demande envoyée le ")]),
                          _vm._v(" "),
                          _c("Date", {
                            attrs: {
                              value:
                                _vm.order.payment_requests.slice(-1)[0]
                                  .created_at,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "DangerousButton",
                            { on: { click: _vm.requestPayment } },
                            [_vm._v("Envoyer la demande")]
                          ),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c("br"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showPaymentRequests
              ? _c("Panel", [
                  _c("h2", [_vm._v("Demandes de paiement")]),
                  _vm._v(" "),
                  _vm.order.payment_requests.length
                    ? _c(
                        "div",
                        [
                          _c("span", [_vm._v("Demande envoyée le ")]),
                          _vm._v(" "),
                          _c("Date", {
                            attrs: {
                              value:
                                _vm.order.payment_requests.slice(-1)[0]
                                  .created_at,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "DangerousButton",
                            { on: { click: _vm.requestPayment } },
                            [_vm._v("Envoyer la demande")]
                          ),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c("br"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "Panel",
              [
                _c("h2", [_vm._v("Transactions bancaires")]),
                _vm._v(" "),
                _c("OrderTransactions", {
                  attrs: { transactions: _vm.order.transactions },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "Panel",
              [
                _c("h2", [_vm._v("📝 Commentaires")]),
                _vm._v(" "),
                _c("Comments"),
                _vm._v(" "),
                _c("div", { staticClass: "global-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button-secondary btn-add-comment",
                      on: { click: _vm.addComment },
                    },
                    [
                      _vm._v(
                        "\n            + Ajouter un commentaire\n          "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : !_vm.error
      ? _c("div", [
          _c("span", { staticClass: "loading-dots" }, [_vm._v("Chargement")]),
        ])
      : _c(
          "div",
          [
            _c("Error", {
              attrs: { details: "impossible de charger cette commande" },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }