<template>
  <div>
    <IcLoader v-if="isOrderEntryDeliveryLoading" class="animate-spin" />
    <div
      class="container"
      v-else
    >
      <div class="property-container">
        <span class="property">Type :</span>
        <span class="value">{{ orderEntryDelivery.label }}</span>
        <span v-if="orderEntryDelivery.shipper" class="shipper-label">
         - {{ orderEntryDelivery.shipper.displayName }}
        </span>
      </div>

      <div class="property-container">
        <span class="property">Statut :</span>
        <span class="value">{{ orderEntryDelivery.status }}</span>
      </div>

      <div class="property-container">
        <span class="property">Id de livraison :</span>
        <span class="value">{{ orderEntryDelivery.id }}</span>
      </div>

      <div v-if="shouldDisplayTaskBlock">
        <span
            v-if="orderEntryDelivery.isTaskSent"
            class="property"
        >
          ✅ Tâche créée
          <template v-if="hasShippingServiceLink">
            <a :href="orderEntryDelivery.pickupShippingServiceLink" target="_blank">Lien vers la tâche <span v-if="hasTwoShippingServiceLinks"> de pickup</span></a>
            <a v-if="hasTwoShippingServiceLinks" :href="orderEntryDelivery.deliveryShippingServiceLink" target="_blank">Lien vers la tâche de livraison</a>
          </template>
        </span>
        <span
          v-else
          class="property"
        >
          ❌ Tâche non créée <template v-if="orderEntryDelivery.status === 'pending_validation'">(en attente d'une validation de la disponibilité produit)</template>
        </span>

        <button
            v-if="shouldDisplayDownloadDeliveryNoteLink"
            @click="downloadOrderEntryDeliveryNote"
            class="button-secondary cta-delivery-note"
        >
          Télécharger l'étiquette
        </button>

        <button
            v-if="shouldDisplayTaskCreationButton"
            class="button-primary cta-task-creation"
            @click="createTask()"
        >
          <IcLoader v-if="isTaskCreationLoading" class="animate-spin"/>
          <span v-else>
            Créer la tâche {{ orderEntryDelivery.shippingService }}
          </span>
        </button>
      </div>
      <span class="error">
        <template v-if="taskCreationError">
          Erreur de création de tâche {{ orderEntryDelivery.shippingService }} : {{ taskCreationError }}
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import * as monolith from '../../api/monolith';
import IcLoader from '../../../assets/img/ic_loader.svg';

export default {
  props: {
    deliveryId: {
      type: String,
      required: true,
    },
  },
  components: {
    IcLoader,
  },
  data() {
    return {
      isOrderEntryDeliveryLoading: false,
      orderEntryDelivery: null,
      taskCreationError: null,
      isTaskCreationLoading: false,
    };
  },
  computed: {
    shouldDisplayTaskCreationButton() {
      return this.orderEntryDelivery && !this.orderEntryDelivery.isTaskSent && this.orderEntryDelivery.status !== 'pending_validation';
    },
    hasShippingServiceLink() {
      return this.orderEntryDelivery && this.orderEntryDelivery.pickupShippingServiceLink !== null;
    },
    hasTwoShippingServiceLinks() {
      return this.orderEntryDelivery && this.orderEntryDelivery.deliveryShippingServiceLink !== this.orderEntryDelivery.pickupShippingServiceLink;
    },
    shouldDisplayTaskBlock() {
      const withoutTaskProviders = ['withdrawal_at_home','personal_delivery', 'custom'];

      return this.orderEntryDelivery && !withoutTaskProviders.includes(this.orderEntryDelivery.provider);
    },
    shouldDisplayDownloadDeliveryNoteLink() {
      return this.orderEntryDelivery && ['mondial_relay', 'colissimo', 'colissimo_psa'].includes(this.orderEntryDelivery.provider) && this.orderEntryDelivery.isTaskSent;
    }
  },
  async mounted() {
    await this.fetchOrderEntryDelivery()
  },
  methods: {
    async fetchOrderEntryDelivery() {
      this.isOrderEntryDeliveryLoading = true;
      try {
        const response = await monolith.getOrderEntryDelivery(this.deliveryId);
        this.orderEntryDelivery = response.item;
      } catch (error) {
        console.error(error);
      }
      this.isOrderEntryDeliveryLoading = false;
    },
    async createTask() {
      this.isTaskCreationLoading = true;
      try {
        await monolith.createTaskForOrderEntryDelivery(this.orderEntryDelivery.id);
      } catch (error) {
        this.taskCreationError = JSON.stringify(JSON.parse(error.response.data.message));

        console.log(error.response);
      }

      this.isTaskCreationLoading = false;
    },
    async downloadOrderEntryDeliveryNote() {
      try {
        const response = await monolith.downloadOrderEntryDeliveryNote(this.orderEntryDelivery.id);

        const url = window.URL.createObjectURL(response.data);
        window.open(url, '_blank');
        window.URL.revokeObjectURL(url);
      } catch (err) {
        console.error('Error while downloading order entry delivery note', err);
      }
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 12px 0;
}
.animate-spin {
  animation: spin 1s linear infinite;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  width: 16px;
  height: 16px;
}

.property-container {
  display: flex;
  margin-bottom: 4px;
  font-size: 1rem;
}

.property {
  display: block;
  margin-right: 4px;
}

.value {
  display: block;
  font-size: 1rem;
  font-weight: bold;
}

.shipper-label {
  margin-left: 4px;
}

.cta-delivery-note, .cta-task-creation {
  margin: 12px 0 0 0;
  width: fit-content;
}

.error {
  color: hsla(0, 100%, 63%, 1);
  margin-top: 12px;
  height: 20px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
