var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c("span", [_vm._v(_vm._s(_vm.offer.label) + " :")]),
    _vm._v(" "),
    _c("span", [
      _vm._v(
        _vm._s(_vm.offer.totalPrice.amount) +
          " " +
          _vm._s(_vm.offer.totalPrice.currency)
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }