var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("h1", [_vm._v("Stripe Tool")]),
    _vm._v(" "),
    _c("h2", [_vm._v("Dissocier")]),
    _vm._v(" "),
    _c("form", { on: { submit: _vm.submit } }, [
      _c("label", [_vm._v("Shop ID")]),
      _c("br"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.shopId,
            expression: "shopId",
          },
        ],
        attrs: { type: "text", placeholder: "81723796960db68ce4c867dd51ae" },
        domProps: { value: _vm.shopId },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.shopId = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("label", [_vm._v("Stripe Account ID")]),
      _c("br"),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.accountId,
            expression: "accountId",
          },
        ],
        attrs: { type: "text", placeholder: "acct_3eQiOaGbPIfPwZU6" },
        domProps: { value: _vm.accountId },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.accountId = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "👉 Attention, le marchand devra recommencer tout le process de création de compte Stripe."
        ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "button-danger", attrs: { type: "submit" } },
        [_vm._v("Dissocier 💣")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }