var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Modal", {
    scopedSlots: _vm._u([
      {
        key: "panel-content",
        fn: function () {
          return [_c("iframe", { attrs: { src: _vm.url, frameborder: "0" } })]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }