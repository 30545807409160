var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "seller-badge",
      class: {
        "bg-pro": _vm.badgeLevel === 10,
        "bg-super-pro": _vm.badgeLevel === 20,
        "bg-pro-ambassador": _vm.badgeLevel === 30,
      },
    },
    [
      _c("img", {
        staticClass: "seller-badge-image",
        attrs: { src: `/assets/img/seller_${_vm.badgeImage}.svg` },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "seller-badge-label" }, [
        _vm._v("\n    " + _vm._s(_vm.badgeLabel) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }