var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", [_vm._v(_vm._s(_vm.provider))]),
      _vm._v(" "),
      _vm.price
        ? [
            _c("br"),
            _vm._v(" "),
            _c("Price", {
              attrs: { price: _vm.delivery.delivery_offer.price },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }