var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.name === "HANDLING"
    ? _c(
        "span",
        [
          _c("span", [_vm._v("Livraison à l'étage")]),
          _vm._v(" "),
          _c("span", [_vm._v("💪")]),
          _vm._v(" "),
          _vm.price
            ? [
                _c("br"),
                _vm._v(" "),
                _c("Price", { attrs: { price: _vm.service.price } }),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }