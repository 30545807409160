var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editable
    ? _c(
        "div",
        _vm._l(_vm.all, function (tag, key) {
          return _c("Tag", {
            key: key,
            attrs: {
              value: key,
              editable: _vm.editable,
              active: _vm.active(key),
            },
            on: { state: _vm.stateHandler },
          })
        }),
        1
      )
    : _c(
        "div",
        _vm._l(_vm.tags, function (tag, key) {
          return _c("Tag", {
            key: key,
            attrs: {
              value: tag,
              editable: _vm.editable,
              active: _vm.active(key),
            },
            on: { state: _vm.stateHandler },
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }