var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Modal", {
    scopedSlots: _vm._u([
      {
        key: "panel-content",
        fn: function () {
          return [
            _c("p", [
              _vm._v(
                _vm._s(_vm.comment.createdBy.firstName) +
                  " - " +
                  _vm._s(_vm.dateFormatted)
              ),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.comment.content))]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }