var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.available
    ? _c(
        "div",
        _vm._l(_vm.links, function (link, index) {
          return _c(
            "a",
            { key: index, attrs: { href: link, target: "_blank" } },
            [
              _vm.code
                ? _c("span", [_vm._v(_vm._s(_vm.code))])
                : _c("span", [_vm._v("Voir")]),
            ]
          )
        }),
        0
      )
    : _c("span", [_vm._v("-")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }